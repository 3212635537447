import React, { createContext, useState, useEffect, useCallback } from 'react';
import { login as apiLogin, register as apiRegister, logout as apiLogout, refreshToken as apiRefreshToken } from '../services/auth';
import { fetchUserData as apiFetchUserData } from '../services/api'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const data = await apiFetchUserData();
      return data;
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('Failed to fetch user data');
      throw err;
    }
  }, []);

  const handleRefreshToken = useCallback(async () => {
    console.log("Starting token refresh...");
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      console.log("No refresh token found");
      setUser(null);
      setLoading(false);
      return false;
    }

    try {
      console.log("Attempting to refresh token");
      const result = await apiRefreshToken();

      if (result.success) {
        console.log("Token refresh successful");
        setUser(result.user);
        return true;
      } else {
        console.log("Token refresh failed:", result.message);
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        return false;
      }
    } catch (err) {
      console.error('Token refresh error:', err);
      setUser(null);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLogin = async (email, password) => {
    setError(null);
    try {
      console.log('Attempting login...');
      const result = await apiLogin(email, password);
      console.log('Login response:', result);

      if (result.success) {
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('refreshToken', result.refresh_token);
        setUser(result.user);
        return true;
      } else {
        setError(result.message);
        return false;
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An unexpected error occurred during login.');
      return false;
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      console.log('Logging out...');
      await apiLogout();
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      setUser(null);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
  }, []);


  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      
      if (token && refreshToken) {
        console.log("Found existing tokens, attempting refresh...");
        // Delay the refresh slightly to ensure proper initialization
        await new Promise(resolve => setTimeout(resolve, 100));
        await handleRefreshToken();
      } else {
        console.log("No existing tokens found");
        setUser(null);
        setLoading(false);
      }
    };

    checkAuth();
  }, [handleRefreshToken]);

  const handleRegister = async (email, password) => {
    setError(null);
    try {
      console.log('Attempting registration...');
      const result = await apiRegister(email, password);
      console.log('Registration response:', result);

      if (result.success) {
        return { success: true, message: result.message };
      } else {
        setError(result.message);
        return { success: false, message: result.message };
      }
    } catch (err) {
      console.error('Registration error:', err);
      const errorMessage = 'An unexpected error occurred during registration.';
      setError(errorMessage);
      return { success: false, message: errorMessage };
    }
  };

  // Periodic token refresh
  useEffect(() => {
    if (user) {
      console.log("Setting up periodic token refresh");
      const refreshInterval = setInterval(() => {
        console.log("Executing periodic token refresh");
        handleRefreshToken();
      }, 14 * 60 * 1000); // Refresh every 14 minutes
      
      return () => {
        console.log("Clearing token refresh interval");
        clearInterval(refreshInterval);
      };
    }
  }, [user, handleRefreshToken]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login: handleLogin,
        register: handleRegister,
        logout: handleLogout,
        refreshToken: handleRefreshToken,
        fetchUserData,
        error,
        setError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
