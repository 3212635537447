import axios from 'axios';
import { getApiUrl } from '../utils/helpers';

const API_URL = getApiUrl();

const authApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

authApi.interceptors.request.use(
  (config) => {
    // Use access token for regular requests
    if (config.url !== '/auth/refresh') {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } else {
      // Use refresh token for refresh requests
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        config.headers.Authorization = `Bearer ${refreshToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async (email, password) => {
  try {
    console.log('API: Attempting login for:', email);
    const response = await authApi.post('/auth/login', { email, password });
    console.log('API: Login response received');
    
    if (response.data.access_token && response.data.refresh_token) {
      return { 
        success: true, 
        user: response.data.user,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token
      };
    }
    return { success: false, message: 'Login failed' };
  } catch (error) {
    console.error('API: Login error:', error.response?.data || error);
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred during login' 
    };
  }
};

export const register = async (email, password) => {
  try {
    console.log('API: Attempting registration');
    const response = await authApi.post('/auth/signup', { email, password });
    if (response.data.success) {
      return {
        success: true,
        user: response.data.user,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token
      };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred during registration' 
    };
  }
};

export const verifyEmail = async (token) => {
  try {
    console.log('API: Attempting email verification');
    const response = await authApi.get(`/auth/verify-email/${token}`);
    console.log('API: Verification response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API: Email verification error:', error);
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred during email verification'
    };
  }
};


export const refreshToken = async () => {
  try {
    console.log('API: Attempting token refresh');
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    console.log("Refresh token:", refreshToken);


    // Create a new axios instance specifically for refresh to avoid interceptors
    const refreshApi = axios.create({
      baseURL: API_URL,
      headers: {
        'Authorization': `Bearer ${refreshToken.toString()}`,
        'Content-Type': 'application/json'
      }
    });

    const response = await refreshApi.post('/auth/refresh');

    console.log('API: Token refresh response received:', response.data);

    if (response.data.access_token && response.data.refresh_token) {
      // Update stored tokens
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      
      return {
        success: true,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        user: response.data.user
      };
    }
    
    throw new Error('Invalid response from refresh token endpoint');
  } catch (error) {
    console.error('API: Token refresh error:', error.response?.data || error);
    // Clear tokens on refresh failure
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred during token refresh' 
    };
  }
};

export const logout = async () => {
  try {
    console.log('API: Attempting logout');
    await authApi.delete('/auth/logout');
    console.log('API: Logout successful');
  } catch (error) {
    console.error('API: Logout error:', error);
  } finally {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }
};

export const deleteAccount = async () => {
  try {
    console.log('API: Attempting account deletion');
    const response = await authApi.delete('/auth/delete-account');
    if (response.status === 200) {
      console.log('API: Account deletion successful');
      return { success: true, message: 'Account deleted successfully' };
    } else {
      return { success: false, message: 'Failed to delete account' };
    }
  } catch (error) {
    console.error('API: Account deletion error:', error);
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred while deleting the account'
    };
  }
};

export const requestPasswordReset = async (email) => {
  try {
    console.log('API: Requesting password reset for:', email);
    const response = await authApi.post('/auth/request-reset', { email });
    console.log('API: Password reset request sent');
    return response.data;
  } catch (error) {
    console.error('API: Password reset request error:', error);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    console.log('API: Attempting password reset');
    const response = await authApi.post('/auth/reset-password', { token, newPassword });
    console.log('API: Password reset successful');
    return response.data;
  } catch (error) {
    console.error('API: Password reset error:', error);
    throw error;
  }
};

authApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      console.log('API: Received 401, attempting token refresh');
      originalRequest._retry = true;
      
      try {
        const refreshResult = await refreshToken();
        if (refreshResult.success) {
          console.log('API: Token refresh successful, retrying original request');
          // Update the authorization header with the new token
          originalRequest.headers['Authorization'] = `Bearer ${refreshResult.access_token}`;
          // Create a new axios instance to avoid interceptor loop
          return axios(originalRequest);
        }
      } catch (refreshError) {
        console.error('API: Token refresh failed:', refreshError);
      }
    }
    
    return Promise.reject(error);
  }
);
